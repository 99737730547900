<template>
  <v-form v-model="valid" ref="form">
    <v-menu
        v-model="datePickerOpen"
        :close-on-content-click="false"
        offset-y
        min-width="290px"
        max-width="290px"
        transition="scale-transition"
        :nudge-right="35">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="start-date"
          v-bind="attrs"
          v-on="on"
          v-on:click:prepend="datePickerOpen = true"
          v-model="dateFormatted"
          :rules="[rules.require]"
          :label="$t('form.event.date')"
          prepend-icon="mdi-calendar"
          required
          readonly
        />
      </template>
      <v-date-picker
        v-model="content.date"
        @input="datePickerOpen = false"
        :allowed-dates="checkAvailability"
        :events="available"
        :locale="$i18n.locale"
        first-day-of-week="1"
        event-color="green lighten-1"
        no-title
        full-width
        scrollable
      />
    </v-menu>
    <v-menu ref="eventTimeMenu"
        v-model="timePickerOpen"
        :close-on-content-click="false"
        offset-y
        min-width="290px"
        transition="scale-transition"
        :nudge-right="35">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field class="start-date"
          v-bind="attrs"
          v-on="on"
          v-on:click:prepend="timePickerOpen = true"
          v-model="content.time"
          :rules="[rules.require]"
          :label="$t('form.event.time')"
          prepend-icon="mdi-clock-time-four-outline"
          required
          readonly
        />
      </template>
      <v-time-picker
        v-if="timePickerOpen"
        v-model="content.time"
        format="24hr"
        :min="availabilityConfig.minTime"
        :max="availabilityConfig.maxTime"
        :allowed-minutes="t => (t % 5) === 0"
        full-width
        scrollable
        @click:minute="$refs.eventTimeMenu.save(content.time)"
      />
    </v-menu>
    <v-row justify="start">
      <v-col class="flex-grow-0">
        <v-text-field class="input-short"
          v-model="content.numberOfPeople"
          :rules="[rules.int, rules.min(10)]"
          @input="checkNumberOfPeople"
          :label="$t('form.event.numberOfPeople')"
          type="number"
          min="0"
          step="1"
          required
        ></v-text-field>
      </v-col>
      <v-col class="flex-grow-0">
        <v-text-field class="input-short"
          v-model="content.numberOfWomen"
          :rules="[rules.int, rules.min(0), rules.max(content.numberOfPeople)]"
          :label="$t('form.event.numberOfWomen')"
          type="number"
          min="0"
          step="1"
          required
        ></v-text-field>
      </v-col>
      <v-col class="flex-grow-0">
        <v-text-field class="input-short"
          v-model="content.numberOfMen"
          :rules="[rules.int, rules.min(0), rules.max(content.numberOfPeople)]"
          :label="$t('form.event.numberOfMen')"
          type="number" min="0" step="1"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-0" justify="start">
      <v-col class="flex-grow-0">
        <v-text-field class="input-short"
          v-model="content.ageFrom"
          :rules="[rules.int, rules.min(16), rules.max(100)]"
          :label="$t('form.event.ageFrom')"
          type="number" min="0" step="1"
          required
        ></v-text-field>
      </v-col>
      <v-col class="flex-grow-0">
        <v-text-field class="input-short"
          v-model="content.ageTo"
          :rules="[rules.int, rules.min(content.ageFrom), rules.max(100)]"
          :label="$t('form.event.ageTo')"
          type="number" min="0" step="1"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-btn class="mt-2" color="primary"
        :disabled="disableNext && !valid"
        @click="next">
      {{ $t('form.next') }}
    </v-btn>
  </v-form>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    validateOnMount: Boolean,
    content: Object,
    rules: Object
  },
  data: () => ({
    valid: false,
    disableNext: false,
    timePickerOpen: false,
    datePickerOpen: false,
    available: null,
    availabilityConfig: {}
  }),
  created() {
    // TODO better reload before opening datepicker
    this.loadAvailability();
  },
  mounted() {
    if (this.validateOnMount) {
      this.validate();
    }
  },
  watch: {
    valid() {
      this.$emit('valid', this.valid);
    }
  },
  computed: {
    dateFormatted() {
      const date = this.content.date;
      if (date) {
        const [year, month, day] = date.split('-');
        return `${day}.${month}.${year}`;
      }
      return '';
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    next() {
      if (this.validate()) {
        this.disableNext = false;
        this.$emit('next');
      } else {
        this.disableNext = true;
      }
    },
    loadAvailability() {
      console.debug('loadAvailability');
      axios.get(this.config.server.url + '/api/availability', this.config.server.options || {})
        .then(response => (this.available = response.data || []))
        .catch(error => (this.available = null));
      axios.get(this.config.server.url + '/api/availability/config', this.config.server.options || {})
        .then(response => (this.availabilityConfig = response.data || {}))
        .catch(error => (this.availabilityConfig = {}));
    },
    checkAvailability(date) {
      return this.available && this.available.includes(date);
    },
    checkNumberOfPeople() {
      if (this.content.numberOfPeople >= 0) {
        this.content.numberOfWomen = Math.min(this.content.numberOfWomen, this.content.numberOfPeople);
        this.content.numberOfMen = Math.min(this.content.numberOfMen, this.content.numberOfPeople);
      }
    }
  }
};
</script>

<style scoped>
.input-short {
  width: 120px;
}
</style>
