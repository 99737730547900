import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.require, _vm.rules.length(100)],"label":_vm.$t('form.location.name'),"required":""},model:{value:(_vm.content.name),callback:function ($$v) {_vm.$set(_vm.content, "name", $$v)},expression:"content.name"}}),_c(VRow,[_c(VCol,[_c(VTextField,{staticClass:"mr-5",attrs:{"rules":[_vm.rules.require, _vm.rules.length(100)],"label":_vm.$t('form.location.street'),"required":""},model:{value:(_vm.content.street),callback:function ($$v) {_vm.$set(_vm.content, "street", $$v)},expression:"content.street"}})],1),_c(VCol,{staticClass:"flex-grow-0"},[_c(VTextField,{staticClass:"input-short",attrs:{"rules":[_vm.rules.length(10)],"label":_vm.$t('form.location.streetNumber')},model:{value:(_vm.content.streetNumber),callback:function ($$v) {_vm.$set(_vm.content, "streetNumber", $$v)},expression:"content.streetNumber"}})],1)],1),_c(VTextField,{attrs:{"rules":[_vm.rules.require, _vm.rules.length(100)],"label":_vm.$t('form.location.zip'),"required":""},model:{value:(_vm.content.zip),callback:function ($$v) {_vm.$set(_vm.content, "zip", $$v)},expression:"content.zip"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.require, _vm.rules.length(100)],"label":_vm.$t('form.location.city'),"required":""},model:{value:(_vm.content.city),callback:function ($$v) {_vm.$set(_vm.content, "city", $$v)},expression:"content.city"}}),_c(VSelect,{attrs:{"rules":[_vm.rules.nonNull],"label":_vm.$t('form.location.allowsEarlyEntry'),"items":[
        { text: _vm.$t('form.yes'), value: true },
        { text: _vm.$t('form.no'), value: false }
      ],"required":""},model:{value:(_vm.content.allowsEarlyEntry),callback:function ($$v) {_vm.$set(_vm.content, "allowsEarlyEntry", $$v)},expression:"content.allowsEarlyEntry"}}),_c(VBtn,{staticClass:"mt-2",attrs:{"color":"primary","disabled":_vm.disableNext && !_vm.valid},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.$t('form.next'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }