<template>
  <v-form v-model="valid" ref="form">
    <v-text-field
      v-model="content.name"
      :rules="[rules.require, rules.length(100)]"
      :label="$t('form.location.name')"
      required
    ></v-text-field>
    <v-row>
      <v-col>
        <v-text-field class="mr-5"
          v-model="content.street"
          :rules="[rules.require, rules.length(100)]"
          :label="$t('form.location.street')"
          required
        ></v-text-field>
      </v-col>
      <v-col class="flex-grow-0">
        <v-text-field class="input-short"
          v-model="content.streetNumber"
          :rules="[rules.length(10)]"
          :label="$t('form.location.streetNumber')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-text-field
      v-model="content.zip"
      :rules="[rules.require, rules.length(100)]"
      :label="$t('form.location.zip')"
      required
    ></v-text-field>
    <v-text-field
      v-model="content.city"
      :rules="[rules.require, rules.length(100)]"
      :label="$t('form.location.city')"
      required
    ></v-text-field>
    <v-select
      v-model="content.allowsEarlyEntry"
      :rules="[rules.nonNull]"
      :label="$t('form.location.allowsEarlyEntry')"
      :items="[
          { text: $t('form.yes'), value: true },
          { text: $t('form.no'), value: false }
        ]"
      required
    />
    <v-btn class="mt-2" color="primary"
        :disabled="disableNext && !valid"
        @click="next">
      {{ $t('form.next') }}
    </v-btn>
  </v-form>
</template>

<script>
export default {
  props: {
    validateOnMount: Boolean,
    content: Object,
    rules: Object
  },
  data: () => ({
    valid: false,
    disableNext: false
  }),
  mounted() {
    if (this.validateOnMount) {
      this.validate();
    }
  },
  watch: {
    valid() {
      this.$emit('valid', this.valid);
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    next() {
      if (this.validate()) {
        this.disableNext = false;
        this.$emit('next');
      } else {
        this.disableNext = true;
      }
    }
  }
};
</script>

<style scoped>
.input-short {
  width: 100px;
}
</style>
