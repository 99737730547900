import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.require, _vm.rules.length(100)],"label":_vm.$t('form.organiser.name'),"required":""},model:{value:(_vm.content.name),callback:function ($$v) {_vm.$set(_vm.content, "name", $$v)},expression:"content.name"}}),_c(VSelect,{attrs:{"rules":[_vm.rules.nonNull],"label":_vm.$t('form.organiser.hasParticipated'),"items":[
        { text: _vm.$t('form.yes'), value: true },
        { text: _vm.$t('form.no'), value: false }
      ],"required":""},model:{value:(_vm.content.hasParticipated),callback:function ($$v) {_vm.$set(_vm.content, "hasParticipated", $$v)},expression:"content.hasParticipated"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.require, _vm.rules.length(100)],"label":_vm.$t('form.organiser.firstName'),"required":""},model:{value:(_vm.content.firstName),callback:function ($$v) {_vm.$set(_vm.content, "firstName", $$v)},expression:"content.firstName"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.require, _vm.rules.length(100)],"label":_vm.$t('form.organiser.lastName'),"required":""},model:{value:(_vm.content.lastName),callback:function ($$v) {_vm.$set(_vm.content, "lastName", $$v)},expression:"content.lastName"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.require, _vm.rules.length(100)],"label":_vm.$t('form.organiser.mobile'),"required":""},model:{value:(_vm.content.mobile),callback:function ($$v) {_vm.$set(_vm.content, "mobile", $$v)},expression:"content.mobile"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.require, _vm.rules.email],"label":_vm.$t('form.organiser.email'),"required":""},model:{value:(_vm.content.email),callback:function ($$v) {_vm.$set(_vm.content, "email", $$v)},expression:"content.email"}}),_c(VSwitch,{staticClass:"ml-3",attrs:{"label":_vm.$t('form.organiser.allowSurvey')},model:{value:(_vm.content.allowSurvey),callback:function ($$v) {_vm.$set(_vm.content, "allowSurvey", $$v)},expression:"content.allowSurvey"}}),_c(VBtn,{staticClass:"mt-2",attrs:{"color":"primary","disabled":_vm.disableNext && !_vm.valid},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.$t('form.next'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }