<template>
  <v-app id="app">
    <v-container id="header" class="py-5 mt-10">
      <v-row>
        <v-col>
          <h1>
            {{ $t('header.title') }}
          </h1>
        </v-col>
        <v-col>
          <v-img class="ml-auto" alt="Helvetia logo"
            max-width="170px" src="./assets/helvetia-logo.svg" />
        </v-col>
      </v-row>
    </v-container>
    <v-main class="mx-auto">
      <Form />
    </v-main>
    <v-footer id="footer" class="my-5 mx-auto">
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
import Form from "./components/Form.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Form,
    Footer
  }
};
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}
ul {
  list-style-type: none;
  padding: 0;
}
h1 {
  font-size: 35px;
}
#header,
#footer {
  max-width: 650px;
  background-color: transparent;
}
</style>
