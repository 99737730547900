export default {
  currentLang: "Italiano",
  header: {
    title: "Iscrizione a Skifit"
  },
  form: {
    next: "Avanti",
    yes: "Sì",
    no: "No",
    organiser: {
      title: "Associazione e contatto",
      name: "Nome dell’associazione",
      hasParticipated: "Già preso parte ad un allenamento Skifit?",
      firstName: "Nome",
      lastName: "Cognome",
      mobile: "Telefono cellulare",
      email: "E-mail",
      allowSurvey: "L’indirizzo e-mail può essere utilizzato per eventuali sondaggi interni sul grado di soddisfazione?",
    },
    location: {
      title: "Luogo dell’evento",
      name: "Nome del luogo dell’evento / palestra",
      street: "Via",
      streetNumber: "Numero",
      zip: "NPA",
      city: "Località",
      allowsEarlyEntry: "È possibile entrare almeno 30 minuti prima?",
    },
    event: {
      title: "Lezione disponibile",
      date: "Data",
      time: "Orario di inizio",
      numberOfPeople: "Numero di persone",
      numberOfWomen: "di cui donne",
      numberOfMen: "di cui uomini",
      ageFrom: "Età da",
      ageTo: "a",
    },
    comments: {
      title: "Osservazioni",
      subtitle: "Facoltativo",
      label: "Osservazioni, istruttrice/istruttore desiderata/o",
    },
    submit: {
      title: "Trasmissione",
      accept: {
        before: "Sono d’accordo con le indicazioni in merito alla",
        link: "informativa sulla protezione dei dati",
        after: ".",
        href: "https://www.helvetia.com/ch/web/it/ci-presentiamo/servizi/contatti/protezionedeidati.html"
      },
      send: "Invia",
      retry: "Riprova",
      success: "Inviato correttamente",
      failure: "Invio non avvenuto",
    },
    rules: {
      required: "Necessario",
      int: "Deve essere un numero intero",
      min: "Minimo {min}",
      max: "Massimo {max}",
      length: "{max} caratteri massimo",
      email: "Indirizzo e-mail non valido",
    }
  },
  footer: {
    impressum: "Nota editoriale",
    termsOfService: "Avvertenze legali",
    dataProtection: "Protezione dei dati",
    company: "Helvetia Assicurazioni Svizzera"
  }
}
