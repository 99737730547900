<template>
  <v-form v-model="valid" ref="form">
    <v-text-field
      v-model="content.name"
      :rules="[rules.require, rules.length(100)]"
      :label="$t('form.organiser.name')"
      required
    ></v-text-field>
    <v-select
      v-model="content.hasParticipated"
      :rules="[rules.nonNull]"
      :label="$t('form.organiser.hasParticipated')"
      :items="[
          { text: $t('form.yes'), value: true },
          { text: $t('form.no'), value: false }
        ]"
      required
    />
    <v-text-field
      v-model="content.firstName"
      :rules="[rules.require, rules.length(100)]"
      :label="$t('form.organiser.firstName')"
      required
    ></v-text-field>
    <v-text-field
      v-model="content.lastName"
      :rules="[rules.require, rules.length(100)]"
      :label="$t('form.organiser.lastName')"
      required
    ></v-text-field>
    <v-text-field
      v-model="content.mobile"
      :rules="[rules.require, rules.length(100)]"
      :label="$t('form.organiser.mobile')"
      required
    ></v-text-field>
    <v-text-field
      v-model="content.email"
      :rules="[rules.require, rules.email]"
      :label="$t('form.organiser.email')"
      required
    ></v-text-field>
    <v-switch class="ml-3"
      v-model="content.allowSurvey"
      :label="$t('form.organiser.allowSurvey')"
    ></v-switch>
    <v-btn class="mt-2" color="primary"
        :disabled="disableNext && !valid"
        @click="next">
      {{ $t('form.next') }}
    </v-btn>
  </v-form>
</template>

<script>
export default {
  props: {
    validateOnMount: Boolean,
    content: Object,
    rules: Object
  },
  data: () => ({
    valid: false,
    disableNext: false
  }),
  mounted() {
    if (this.validateOnMount) {
      this.validate();
    }
  },
  watch: {
    valid() {
      this.$emit('valid', this.valid);
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    next() {
      if (this.validate()) {
        this.disableNext = false;
        this.$emit('next');
      } else {
        this.disableNext = true;
      }
    }
  }
};
</script>

<style scoped>
.input-short {
  width: 100px;
}
</style>
