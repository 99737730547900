export default {
  currentLang: "Français",
  header: {
    title: "Inscription à Skifit"
  },
  form: {
    next: "Continuer",
    yes: "Oui",
    no: "Non",
    organiser: {
      title: "Association et contact",
      name: "Nom de l'association",
      hasParticipated: "Avez-vous déjà participé à Skifit?",
      firstName: "Prénom",
      lastName: "Nom",
      mobile: "Portable",
      email: "Courriel",
      allowSurvey: "Mon adresse courriel peut-elle être utilisée pour d'éventuelles enquêtes de satisfaction internes?",
    },
    location: {
      title: "Lieu de la manifestation",
      name: "Nom du lieu de la manifestation / gymnase",
      street: "Rue",
      streetNumber: "Numéro",
      zip: "NPA",
      city: "Localité",
      allowsEarlyEntry: "Accès possible au moins 30 min. à l'avance?",
    },
    event: {
      title: "Session disponible",
      date: "Date",
      time: "Heure de début",
      numberOfPeople: "Nombre de personnes",
      numberOfWomen: "dont femmes",
      numberOfMen: "dont hommes",
      ageFrom: "Age de",
      ageTo: "à",
    },
    comments: {
      title: "Remarques",
      subtitle: "Facultatif",
      label: "Remarques, instructeur-trice souhaité-e",
    },
    submit: {
      title: "Transmission",
      accept: {
        before: "En envoyant ce formulaire, je confirme que j'ai lu et compris les",
        link: "informations sur la protection des données",
        after: ".",
        href: "https://www.helvetia.com/ch/web/fr/notre-profil/services/contact/protection-des-donnees.html"
      },
      send: "Envoyer",
      retry: "Réessayer",
      success: "Inscription validée",
      failure: "Échec de la transmission",
    },
    rules: {
      required: "Requis",
      int: "Doit être un nombre entier",
      min: "Au moins {min}",
      max: "Maximum {max}",
      length: "{max} caractères maximum",
      email: "Adresse courriel non valide",
    }
  },
  footer: {
    impressum: "Informations juridiques",
    termsOfService: "Mentions légales",
    dataProtection: "Protection des données",
    company: "Helvetia Assurances Suisse"
  }
}
