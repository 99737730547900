import { VApp } from 'vuetify/lib/components/VApp';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"app"}},[_c(VContainer,{staticClass:"py-5 mt-10",attrs:{"id":"header"}},[_c(VRow,[_c(VCol,[_c('h1',[_vm._v(" "+_vm._s(_vm.$t('header.title'))+" ")])]),_c(VCol,[_c(VImg,{staticClass:"ml-auto",attrs:{"alt":"Helvetia logo","max-width":"170px","src":require("./assets/helvetia-logo.svg")}})],1)],1)],1),_c(VMain,{staticClass:"mx-auto"},[_c('Form')],1),_c(VFooter,{staticClass:"my-5 mx-auto",attrs:{"id":"footer"}},[_c('Footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }