<template>
  <v-form v-model="valid" ref="form">
    <v-switch class="ml-3 mb-3"
        v-model="acceptedToS"
        :disabled="(sent && successful)">
      <template v-slot:label>
        <p class="tos ml-3">
          {{ $t('form.submit.accept.before') }}
          <a @click.stop :href="$t('form.submit.accept.href')" target="_blank">
            {{ $t('form.submit.accept.link') }}
          </a>
          {{ $t('form.submit.accept.after') }}
        </p>
      </template>
    </v-switch>
    <v-alert elevation="2"
        v-model="showAlert"
        :dismissible="!successful"
        :type="response ? response.status : 'info'">
      {{ response ? response.msg : '' }}
    </v-alert>
    <v-btn color="success" elevation="2" x-large
        v-if="!showAlert"
        :disabled="!acceptedToS || (sent && successful)"
        :loading="sent && !response"
        @click="submit">
      {{ sent ? $t('form.submit.retry') : $t('form.submit.send') }}
    </v-btn>
  </v-form>
</template>

<script>
export default {
  props: {
    validateOnMount: Boolean,
    sent: Boolean,
    response: Object
  },
  data: () => ({
    valid: false,
    acceptedToS: false,
    showAlert: false
  }),
  mounted() {
    if (this.validateOnMount) {
      this.validate();
    }
  },
  watch: {
    valid() {
      this.$emit('valid', this.valid);
    },
    response() {
      this.showAlert = !!this.response;
    }
  },
  computed: {
    successful() {
      return this.response && this.response.success;
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    submit() {
      this.$emit('submit');
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
p.tos {
  padding: 0;
  margin: 0;
}
</style>
