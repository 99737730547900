export default {
  currentLang: "Deutsch",
  header: {
    title: "Skifit Anmeldung",
  },
  form: {
    next: "Weiter",
    yes: "Ja",
    no: "Nein",
    organiser: {
      title: "Verein und Kontakt",
      name: "Vereinsname",
      hasParticipated: "Bereits einmal beim Skifit teilgenommen?",
      firstName: "Vorname",
      lastName: "Nachname",
      mobile: "Mobiltelefon",
      email: "E-Mail",
      allowSurvey: "Darf die E-Mail-Ad­res­se für allfällige interne Befragungen über meine Zufriedenheit genutzen werden?",
    },
    location: {
      title: "Veranstaltungsort",
      name: "Name Veranstaltungsort / Turnhalle",
      street: "Strasse",
      streetNumber: "Nummer",
      zip: "PLZ",
      city: "Ortschaft",
      allowsEarlyEntry: "Zugang mind. 30 Min. vorher möglich?",
    },
    event: {
      title: "Verfügbare Lektion",
      date: "Datum",
      time: "Startzeit",
      numberOfPeople: "Anzahl Personen",
      numberOfWomen: "davon Frauen",
      numberOfMen: "davon Männer",
      ageFrom: "Alter von",
      ageTo: "bis",
    },
    comments: {
      title: "Bemerkungen",
      subtitle: "Optional",
      label: "Bemerkungen, gewünschte/r Instruktor/in",
    },
    submit: {
      title: "Übermittlung",
      accept: {
        before: "Mit der Absendung bestätige ich, die",
        link: "Hinweise zum Datenschutz",
        after: "gelesen und verstanden zu haben.",
        href: "https://www.helvetia.com/ch/web/de/ueber-uns/services/kontakt/datenschutz.html"
      },
      send: "Absenden",
      retry: "Erneut versuchen",
      success: "Übermittlung erfolgreich",
      failure: "Übermittlung fehlgeschlagen",
    },
    rules: {
      required: "Wird benötigt",
      int: "Muss ganzzahlig sein",
      min: "Mindestens {min}",
      max: "Maximal {max}",
      length: "Maximal {max} Zeichen",
      email: "E-mail ungültig"
    }
  },
  footer: {
    impressum: "Impressum",
    termsOfService: "Rechtliche Hinweise",
    dataProtection: "Datenschutz",
    company: "Helvetia Versicherungen Schweiz"
  }
}
