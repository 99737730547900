import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from './i18n';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // Helvetia color scheme
        primary: '#00818f',
        secondary: '#8b9196',
        accent: '#8861a9',
        success: '#46a9b4',
        info: '#8861a9',
        warning: '#c31622',
        error: '#c31622',
      },
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});
