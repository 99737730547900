import Vue from "vue";
import App from "./App.vue";
import i18n from './plugins/i18n';
import vuetify from "./plugins/vuetify";
import config from "./config.js";

Vue.config.productionTip = false;

Vue.prototype.config = config;

new Vue({
  i18n,
  vuetify,
  render: function(h) {
    return h(App);
  }
}).$mount("#app");
