<template>
  <v-row justify="center" no-gutters>
    <v-btn text class="my-2" color="primary"
        href="https://www.helvetia.com/ch/web/de/ueber-uns/services/kontakt/impressum.html"
        target="_blank">
      {{ $t('footer.impressum') }}
    </v-btn>
    <v-btn text class="my-2" color="primary"
        href="https://www.helvetia.com/ch/web/de/ueber-uns/services/kontakt/rechtliche-hinweise.html"
        target="_blank">
      {{ $t('footer.termsOfService') }}
    </v-btn>
    <v-btn text class="my-2" color="primary"
        href="https://www.helvetia.com/ch/web/de/ueber-uns/services/kontakt/datenschutz.html"
        target="_blank">
      {{ $t('footer.dataProtection') }}
    </v-btn>
    <v-col class="py-4 text-center secondary--text" cols="12">
      © {{ new Date().getFullYear() }} · {{ $t('footer.company') }}
    </v-col>
  </v-row>
</template>

<script>
export default {
};
</script>

<style scoped>
</style>
