<template>
  <v-form v-model="valid" ref="form">
    <v-textarea
      v-model="content.comments"
      :rules="[rules.length(500)]"
      :label="$t('form.comments.label')"
    ></v-textarea>
    <v-btn class="mt-2" color="primary"
        :disabled="disableNext && !valid"
        @click="next">
      {{ $t('form.next') }}
    </v-btn>
  </v-form>
</template>

<script>
export default {
  props: {
    validateOnMount: Boolean,
    content: Object,
    rules: Object
  },
  data: () => ({
    valid: false,
    disableNext: false
  }),
  mounted() {
    if (this.validateOnMount) {
      this.validate();
    }
  },
  watch: {
    valid() {
      this.$emit('valid', this.valid);
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    next() {
      if (this.validate()) {
        this.disableNext = false;
        this.$emit('next');
      } else {
        this.disableNext = true;
      }
    }
  }
};
</script>

<style scoped>
</style>
