import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const supportedLocales = Object.freeze([ 'de', 'fr', 'it' ]);

// import all supported locales
import locale_de from '../locale/de';
import locale_fr from '../locale/fr';
import locale_it from '../locale/it';
import locale_vuetify_de from 'vuetify/lib/locale/de';
import locale_vuetify_fr from 'vuetify/lib/locale/fr';
import locale_vuetify_it from 'vuetify/lib/locale/it';

// build messages object
const messages = {
  de: locale_de,
  fr: locale_fr,
  it: locale_it
};
messages.de.$vuetify = locale_vuetify_de;
messages.fr.$vuetify = locale_vuetify_fr;
messages.it.$vuetify = locale_vuetify_it;

function getStartingLocale() {
  return [
    getBrowserLocale(),
    process.env.VUE_APP_I18N_LOCALE
  ].find(l => supportedLocales.includes(l)) || 'de';
}

function getBrowserLocale() {
  const navigatorLocale = (navigator.languages !== undefined)
      ? navigator.languages[0]
      : navigator.language
  return (navigatorLocale || '').trim().split(/-|_/)[0];
}

const i18n = new VueI18n({
  locale: getStartingLocale(),
  messages
});
i18n.supportedLocales = supportedLocales;

export default i18n;
