<template>
  <div id="form">

    <div id="langSwitcher">
      <v-select outlined dense
        v-model="content.organiser.lang"
        :items="$i18n.supportedLocales.map(locale => ({
          text : locale.toUpperCase(),
          value : locale
        }))"
      />
    </div>

    <v-stepper v-model="stepper.current" vertical width="650px" max-width="650px">
      <v-stepper-step step="1"
          :complete="checkStepComplete(1)"
          :editable="checkStepEditable(1)"
          :rules="[() => checkStepValid(1)]"
          edit-icon="mdi-check">
        {{ $t('form.organiser.title') }}
      </v-stepper-step>
      <v-stepper-content step="1">
        <FormPartOrganiser
          v-if="stepper.current == 1"
          :validateOnMount="stepper.current < stepper.max"
          :content="content.organiser"
          :rules="rules"
          @valid="v => validateStep(1, v)"
          @next="nextStep"
        />
      </v-stepper-content>

      <v-stepper-step step="2"
          :complete="checkStepComplete(2)"
          :editable="checkStepEditable(2)"
          :rules="[() => checkStepValid(2)]"
          edit-icon="mdi-check">
        {{ $t('form.location.title') }}
      </v-stepper-step>
      <v-stepper-content step="2">
        <FormPartLocation
          v-if="stepper.current == 2"
          :validateOnMount="stepper.current < stepper.max"
          :content="content.location"
          :rules="rules"
          @valid="v => validateStep(2, v)"
          @next="nextStep"
        />
      </v-stepper-content>

      <v-stepper-step step="3"
          :complete="checkStepComplete(3)"
          :editable="checkStepEditable(3)"
          :rules="[() => checkStepValid(3)]"
          edit-icon="mdi-check">
        {{ $t('form.event.title') }}
      </v-stepper-step>
      <v-stepper-content step="3">
        <FormPartEvent
          v-if="stepper.current == 3"
          :validateOnMount="stepper.current < stepper.max"
          :content="content.event"
          :rules="rules"
          @valid="v => validateStep(3, v)"
          @next="nextStep"
        />
      </v-stepper-content>

      <v-stepper-step step="4"
          :complete="checkStepComplete(4)"
          :editable="checkStepEditable(4)"
          :rules="[() => checkStepValid(4)]"
          edit-icon="mdi-check">
        {{ $t('form.comments.title') }}
        <small>{{ $t('form.comments.subtitle') }}</small>
      </v-stepper-step>
      <v-stepper-content step="4">
        <FormPartComments
          v-if="stepper.current == 4"
          :validateOnMount="stepper.current < stepper.max"
          :content="content.event"
          :rules="rules"
          @valid="v => validateStep(4, v)"
          @next="nextStep"
        />
      </v-stepper-content>

      <v-stepper-step step="5"
          :complete="checkStepComplete(5)"
          :editable="checkStepEditable(5)"
          :rules="[() => checkStepValid(5)]"
          edit-icon="mdi-check">
        {{ $t('form.submit.title') }}
      </v-stepper-step>
      <v-stepper-content step="5">
        <FormPartSubmit
          v-if="stepper.current == 5"
          :validateOnMount="stepper.current < stepper.max"
          :sent="submit.sent"
          :response="submit.response"
          @valid="v => validateStep(5, v)"
          @submit="submitForm"
        />
      </v-stepper-content>
    </v-stepper>

  </div>
</template>

<script>
import axios from 'axios';

import FormPartOrganiser from './FormParts/Organiser.vue';
import FormPartLocation from './FormParts/Location.vue';
import FormPartEvent from './FormParts/Event.vue';
import FormPartComments from './FormParts/Comments.vue';
import FormPartSubmit from './FormParts/Submit.vue';

export default {
  components: {
    FormPartOrganiser,
    FormPartLocation,
    FormPartEvent,
    FormPartComments,
    FormPartSubmit
  },
  data: () => ({
    stepper: {
      current: 1,
      max: 1,
      last: 5
    },
    valid: {
      onMount: false,
      data: []
    },
    submit: {
      sent: false,
      response: null
    },
    content: {
      organiser: {
        lang: '',
        name: '',
        hasParticipated: null,
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        allowSurvey: true
      },
      location: {
        name: '',
        street: '',
        streetNumber: '',
        zip: '',
        city: '',
        allowsEarlyEntry: null
      },
      event: {
        date: null,
        time: null,
        numberOfPeople: 0,
        numberOfWomen: 0,
        numberOfMen: 0,
        ageFrom: 0,
        ageTo: 0,
        comments: ''
      }
    }
  }),
  computed: {
    lang() {
      return this.content.organiser.lang;
    },
    rules() {
      return {
        nonNull: v => v != null || this.$t('form.rules.required'),
        require: v => !!v || this.$t('form.rules.required'),
        int: v => v % 1 == 0 || this.$t('form.rules.int'),
        min: min => v => parseInt(v) >= min || this.$t('form.rules.min', { min }),
        max: max => v => parseInt(v) <= max || this.$t('form.rules.max', { max }),
        length: max => v => v.length <= max || this.$t('form.rules.length', { max }),
        email: v => /[\S]+@[\S]+\.[\S]+/.test(v) || this.$t('form.rules.email')
      };
    }
  },
  created() {
    this.content.organiser.lang = this.$i18n.locale;
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
    },
    stepper: {
      handler(stepper) {
        stepper.max = Math.max(stepper.current, stepper.max);
        if (stepper.current == stepper.last) {
          this.submitReset();
        }
      },
      deep: true
    }
  },
  methods: {
    validateStep(step, valid) {
      this.valid.data[step - 1] = !!valid;
      console.debug('validateStep', step, this.valid.data);
    },
    nextStep() {
      this.stepper.current++;
      console.debug('nextStep', this.stepper, this.content);
    },
    checkStepEditable(step) {
      return (!this.submit.sent
          && (step <= this.stepper.max)
          && (step != this.stepper.current))
          || (this.config.env === 'test');
    },
    checkStepComplete(step) {
      return (step < this.stepper.last)
          ? (step < this.stepper.max) && this.valid.data[step - 1]
          : !!this.submit.response;
    },
    checkStepValid(step) {
      return this.valid.data[step - 1]
          || (step > this.stepper.max)
          || (step == this.stepper.current);
    },
    submitForm() {
      const failStep = this.validateAllForms();
      if (failStep > 0) {
        this.valid.onMount = true;
        this.stepper.current = failStep;
      } else {
        this.submit.sent = true;
        axios.post(this.config.server.url + "/api/inquire", this.content, this.config.server.options || {})
          .then(this.submitSuccess)
          .catch(this.submitFailure);
      }
    },
    validateAllForms() {
      return this.stepper.max > this.valid.data.map(x => x ? 1 : 0).reduce((a, b) => a + b, 0)
        ? this.valid.data.findIndex(valid => !valid) + 1
        : 0;
    },
    submitSuccess(response) {
      this.submit.response = {
        success: !!response.data,
        status: response.data ? 'success' : 'warn',
        msg: response.data
            ? this.$t('form.submit.success')
            : this.$t('form.submit.failure')
      };
      console.debug('submitted', response);
    },
    submitFailure(error) {
      this.submit.response = {
        success: false,
        status: 'error',
        msg: this.$t('form.submit.failure') + ': '
            + error.message
      };
      console.warn('submitting form failed with', error);
    },
    submitReset() {
      this.submit.sent = false;
      this.submit.response = null;
      this.valid.onMount = false;
    }
  }
};
</script>

<style scoped>
#langSwitcher {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  width: 100px;
}
.input-short {
  width: 100px;
}
</style>
